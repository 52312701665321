import React from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';
import logoGold from './img/logo-gold-600.png';
import s from './Logo.module.scss';

const propTypes = {
  className: PropTypes.string,
};

const imageSet = [
  {
    src: logoGold,
  },
];

const Logo = ({ className = '' }) => {
  return (
    <div className={[s.Logo, className].join(' ')}>
      <Image imageSet={imageSet} contain bottom alt="Harry Potter Logo" />
    </div>
  );
};

Logo.propTypes = propTypes;
export default Logo;
