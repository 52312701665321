import Bugsnag from '@bugsnag/js';
import {
  MOTIF_SEARCH_API_URL,
  MOTIF_SUGGEST_API_URL,
  SEARCH_FILTERS,
  SEARCH_SORTS,
  NUMBER_OF_ITEMS_PER_PAGE,
  MOTIF_SEARCH_DOCUMENT_TYPES,
  MOTIF_FILTER_TO_FACET_MAP,
} from '@/src/constants/search';
import { findUrl, findDisplayName } from '@utils/hubs';

const pageMap = {};

const executeMotifQuery = async (searchType, query, options) => {
  const url = searchType === 'search' ? MOTIF_SEARCH_API_URL : MOTIF_SUGGEST_API_URL;
  const queryString = new URLSearchParams({
    ...options,
    term: query,
  }).toString();

  try {
    const response = await fetch(`${url}?${queryString}`, {
      method: 'GET',
      headers: {
        Authorization: 'none',
      },
    });

    if (!response.ok) {
      const error = new Error(response.description);
      error.response = response;
      throw error;
    }

    return response.json();
  } catch (e) {
    Bugsnag.notify(e);

    return {
      term: query,
      correctedTerm: null,
      count: 0,
      total: 0,
      pageInfo: { hasNextPage: false, endCursor: null },
      facets: {},
      results: [],
    };
  }
};

/* eslint-disable no-param-reassign */
const parseMotifRecord = (record) => {
  const tags = record?.tags?.length
    ? record?.tags?.map((tag) => {
        return {
          name: tag,
        };
      })
    : [];

  record.contentfulTypeId = record.contentTypeId;
  switch (record?.contentTypeId) {
    case MOTIF_SEARCH_DOCUMENT_TYPES.article:
      record.image = record.mainImageUrl;
      record.href = findUrl(tags, record.contentfulExternalId);
      record.title = record.displayTitle;
      record.description = record.intro;
      record.type = findDisplayName(tags)?.toUpperCase() || 'Article';
      record.publishDate = record.activationDate || record.createdAt;
      break;
    case MOTIF_SEARCH_DOCUMENT_TYPES.collection:
      record.image = record.mainImageUrl;
      record.href = `/collections/${record.contentfulExternalId}`;
      record.title = record.displayTitle;
      record.description = record.shortDescription;
      record.type = 'Collection';
      record.publishDate = record.createdAt;
      break;
    case MOTIF_SEARCH_DOCUMENT_TYPES.page:
      record.image = record.mainImageUrl;
      record.href = `/${record.contentfulExternalId}`;
      record.title = record.displayTitle || record.entryTitle;
      record.description = record.searchDescription || '';
      record.type = 'Page';
      record.publishDate = record.createdAt;
      break;
    case MOTIF_SEARCH_DOCUMENT_TYPES.searchResult:
      record.image = record.mainImageUrl;
      record.href = record.url;
      record.title = record.displayTitle;
      record.description = record.description || '';
      record.type = 'Page';
      record.publishDate = record.createdAt;
      record.loginRequired = record.loginRequired?.toString();
      break;
    case MOTIF_SEARCH_DOCUMENT_TYPES.writingByJKRowling:
      record.image = record.mainImageUrl;
      record.href = findUrl(tags, record.contentfulExternalId);
      record.title = record.displayTitle;
      record.description = record.intro || record.bodyText || '';
      record.type = 'J.K. Rowling Original';
      record.publishDate = record.activationDate;
      break;
    case MOTIF_SEARCH_DOCUMENT_TYPES.factFilePage:
      record.image = record.images?.[0];
      record.href = `/fact-file/${record.category}/${record.contentfulExternalId}`;
      record.title = record.displayTitle;
      record.description = record.bodyText || '';
      record.type = 'Fact File';
      record.publishDate = record.createdAt;
      break;
    /* istanbul ignore next */
    default:
      break;
  }
};
/* eslint-enable no-param-reassign */

const parseMotifResponse = (motifResponse) => {
  const parsedResponse = motifResponse;

  parsedResponse.filterCounts = {};
  parsedResponse.recordCount = motifResponse?.count;
  parsedResponse.totalResultCount = parsedResponse?.total || 0;

  const newsArticleCount = parsedResponse.facets?.news?.total || 0;
  const quizArticleCount = parsedResponse.facets?.quizzes?.total || 0;
  const articleCount = parsedResponse.facets?.articles?.total || 0;
  const curatedPagesCount = parsedResponse.facets?.curatedPages?.total || 0;
  const factFileCount = parsedResponse.facets?.factFiles?.total || 0;
  const writingByJKRowlingCount = parsedResponse.facets?.jkRowlingArchive?.total || 0;

  Object.keys(SEARCH_FILTERS).forEach((filterName) => {
    const filter = SEARCH_FILTERS[filterName];
    let count = 0;
    switch (filter) {
      case SEARCH_FILTERS.everything:
        count = parsedResponse.totalResultCount || 0;
        break;
      case SEARCH_FILTERS.articles:
        count = articleCount;
        break;
      case SEARCH_FILTERS.news:
        count = newsArticleCount;
        break;
      case SEARCH_FILTERS.quizzes:
        count = quizArticleCount;
        break;
      case SEARCH_FILTERS.writingByJKRowling:
        count = writingByJKRowlingCount;
        break;
      case SEARCH_FILTERS.curatedPages:
        count = curatedPagesCount;
        break;
      case SEARCH_FILTERS.factFiles:
        count = factFileCount;
        break;
      /* istanbul ignore next */
      default:
        break;
    }
    parsedResponse.filterCounts[filter] = count;
  });

  parsedResponse.allResults = parsedResponse.results;
  parsedResponse.allResults.forEach((record) => {
    parseMotifRecord(record);
  });

  return parsedResponse;
};

export const motifSearch = async (
  query = '',
  { filters = [], sortBy = 'relevance', page = 1, resultsPerPage = NUMBER_OF_ITEMS_PER_PAGE } = {},
) => {
  // See if the page integer number has a mapping to a cursor value for this query
  const currentPageMapKey = JSON.stringify({ query, filters, sortBy, page, resultsPerPage });
  const pageCursor = pageMap[currentPageMapKey] ? pageMap[currentPageMapKey] : null;

  // If subsequent page is requested and no pageCursor is present, don't fetch
  // the first page again. Instead, return
  if (page > 1 && pageCursor === null) {
    return {
      term: query,
      correctedTerm: null,
      count: 0,
      total: 0,
      pageInfo: { hasNextPage: false, endCursor: null },
      facets: {},
      results: [],
    };
  }

  let facets;
  let types;
  if (filters.includes(SEARCH_FILTERS.everything) || filters.length === 0) {
    types = [
      MOTIF_SEARCH_DOCUMENT_TYPES.article,
      MOTIF_SEARCH_DOCUMENT_TYPES.collection,
      MOTIF_SEARCH_DOCUMENT_TYPES.page,
      MOTIF_SEARCH_DOCUMENT_TYPES.searchResult,
      MOTIF_SEARCH_DOCUMENT_TYPES.writingByJKRowling,
      MOTIF_SEARCH_DOCUMENT_TYPES.factFilePage,
    ];
  } else {
    facets = [];
    filters.forEach((filter) => {
      facets.push(MOTIF_FILTER_TO_FACET_MAP[filter]);
    });
  }

  let sort;
  if (sortBy !== SEARCH_SORTS.relevance) {
    sort = sortBy === SEARCH_SORTS.oldToNew ? 'published' : '-published';
  }

  const options = {
    afterCursor: pageCursor,
    count: resultsPerPage,
  };

  if (sort) {
    options.sort = sort;
  }

  if (facets) {
    options.facet = facets.join(',');
  }

  if (types) {
    options.types = types.join(',');
  }

  const motifResponse = await executeMotifQuery('search', query, options);
  const parsedMotifResponse = parseMotifResponse(motifResponse);

  // save the cursor for the next page and map it to the next integer page number
  const nextPageMapKey = JSON.stringify({ query, filters, sortBy, page: page + 1, resultsPerPage });
  pageMap[nextPageMapKey] = parsedMotifResponse?.pageInfo?.endCursor || null;

  return parsedMotifResponse;
};

export const motifSuggest = async (query = '') => {
  const motifResponse = await executeMotifQuery('suggest', query, {});
  /* istanbul ignore next */
  return motifResponse?.results || [];
};

export const motifSuggestTerms = async (query, _, numberOfTerms = 5) => {
  if (!query) return [];
  const suggestions = await motifSuggest(query);
  return suggestions.slice(0, numberOfTerms);
};
