import React from 'react';
import PropTypes from 'prop-types';
import s from './HouseAvatar.module.scss';
import noHouseAvatar from './svgs/no_house.svg';
import gryffindorAvatar from './svgs/gryffindor.svg';
import hufflepuffAvatar from './svgs/hufflepuff.svg';
import ravenclawAvatar from './svgs/ravenclaw.svg';
import slytherinAvatar from './svgs/slytherin.svg';
import gryffindorCrest from './svgs/gryffindor-crest.svg';
import hufflepuffCrest from './svgs/hufflepuff-crest.svg';
import ravenclawCrest from './svgs/ravenclaw-crest.svg';
import slytherinCrest from './svgs/slytherin-crest.svg';

const propTypes = {
  house: PropTypes.string,
  avatarImage: PropTypes.string,
};

const HouseAvatar = ({ house = '', avatarImage = '' }) => {
  return (
    <div
      className={[
        s.root,
        avatarImage ? s.hasAvatar : '',
        house ? s[house.toLowerCase()] : s.noHouse,
      ].join(' ')}
      data-testid="houseAvatar"
      style={{
        '--noHouseAvatar': `url(${noHouseAvatar})`,
        '--gryffindorAvatar': `url(${gryffindorAvatar})`,
        '--hufflepuffAvatar': `url(${hufflepuffAvatar})`,
        '--ravenclawAvatar': `url(${ravenclawAvatar})`,
        '--slytherinAvatar': `url(${slytherinAvatar})`,
        '--gryffindorCrest': `url(${gryffindorCrest})`,
        '--hufflepuffCrest': `url(${hufflepuffCrest})`,
        '--ravenclawCrest': `url(${ravenclawCrest})`,
        '--slytherinCrest': `url(${slytherinCrest})`,
        '--avatarImage': `url(${avatarImage})`,
      }}
    >
      <div className={s.crest} />
    </div>
  );
};

HouseAvatar.propTypes = propTypes;
export default HouseAvatar;
