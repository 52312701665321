import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

export function useWindowWidth() {
  const [width, setWidth] = useState(process.browser ? window.innerWidth : 0);

  useEffect(() => {
    let handleResize = () => setWidth(window.innerWidth);
    handleResize();
    handleResize = debounce(handleResize, 300);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [width, setWidth]);

  return width;
}

export function useWindowHeight() {
  const [height, setHeight] = useState(process.browser ? window.innerHeight : 0);

  useEffect(() => {
    let handleResize = () => setHeight(window.innerHeight);
    handleResize();
    handleResize = debounce(handleResize, 300);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [height, setHeight]);

  return height;
}
