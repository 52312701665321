import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  blur: false,
  navigationHiddenByScroll: false,
  navRibbonActive: false,
};

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState(initialState);

export const setBlur = (blur) => {
  setGlobalState('blur', blur);
};

export const setNavigationHiddenByScroll = (navigationHiddenByScroll) => {
  setGlobalState('navigationHiddenByScroll', navigationHiddenByScroll);
};

export const setNavRibbonActive = (navRibbonActive) => {
  setGlobalState('navRibbonActive', navRibbonActive);
};

export { useGlobalState, getGlobalState };
