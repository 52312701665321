/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGlobalState as useNavigationGlobalState } from '@/src/globalStates/NavigationState';
import { setIsSearching as setGlobalSearchState } from '@/src/globalStates/SearchState';
import { SEARCH } from '@/src/constants/routes';
import {
  SUGGEST_DEBOUNCE_TIMEOUT,
  SEARCH_CHARACTER_LIMIT,
  TRENDING_INDEX_SESSION,
} from '@constants/search';
import cx from 'classnames';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';
import SearchClient from '@utils/search/searchClient';
import searchImage from '../../images/icons/search.svg';
import Image from '../Image';
import s from './SearchInput.module.scss';

const propTypes = {
  onSearching: PropTypes.func,
  onSearchFocus: PropTypes.func,
};

const SearchInput = ({ onSearching = () => {}, onSearchFocus = () => {} }) => {
  const searchClient = new SearchClient();
  const [isSearching, setSearching] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [trendingTerms, setTrendingTerms] = useState([]);
  const [navigationHiddenByScroll] = useNavigationGlobalState('navigationHiddenByScroll');
  const [inputAnimationComplete, setInputAnimationComplete] = useState(false);
  const searchInput = useRef(null);
  const router = useRouter();

  useEffect(() => {
    /* istanbul ignore next */
    if (navigationHiddenByScroll) {
      searchInput.current.blur();
    }
  }, [navigationHiddenByScroll]);

  useEffect(() => {
    const fetchTrendingTerms = async () => {
      setTrendingTerms(await searchClient.getTrendingTerms());
    };
    fetchTrendingTerms();

    const handleEscape = (e) => {
      if (e.key === 'Escape' && document.activeElement === searchInput.current) {
        searchInput.current.blur();
      }
    };

    window.addEventListener('keydown', handleEscape);

    return () => {
      window.removeEventListener('keydown', handleEscape);
    };
  }, []);

  const fetchSuggestedTerms = useCallback(
    debounce(async (searchTerm) => {
      const suggestedTermsResponse = await searchClient.suggestTerms(searchTerm);
      setSearchSuggestions(suggestedTermsResponse);
    }, SUGGEST_DEBOUNCE_TIMEOUT),
    [],
  );

  const onNavSearchFocus = () => {
    setGlobalSearchState(true);
    setSearching(true);
    onSearchFocus();

    // fixes firefox issue where input loses focus while closing sidebar
    setTimeout(() => {
      if (searchInput.current !== null) searchInput.current.focus();
    }, 0);
  };

  const onNavSearchBlur = () => {
    setTimeout(() => {
      setTimeout(() => {
        setSearchValue('');
        setSearching(false);
        setGlobalSearchState(false);
      }, 300);
    }, 200);
  };

  const onInputChange = (event) => {
    setSearchValue(event.target.value);
    fetchSuggestedTerms(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (router.pathname === SEARCH && event.target.value === '') return;
      router.push(
        event?.target?.value ? `/search?term=${encodeURIComponent(event.target.value)}` : '/search',
      );
      setSearching(false);
    }
  };

  const handleTermClick = (trend) => {
    searchInput.current.blur();
    router.push(`/search?term=${encodeURIComponent(trend)}`);
  };

  const handleInputAnimationComplete = () => {
    setInputAnimationComplete(true);
  };

  useEffect(() => {
    onSearching(isSearching);
    if (isSearching) {
      searchInput.current.focus();
    }
  }, [isSearching, onSearching]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
      <div className={s.searchInput} tabIndex="0">
        <input
          type="text"
          onFocus={onNavSearchFocus}
          onBlur={onNavSearchBlur}
          onChange={onInputChange}
          onKeyDown={handleKeyDown}
          ref={searchInput}
          data-testid="searchInputHeader"
          placeholder="Search"
          maxLength={SEARCH_CHARACTER_LIMIT}
          onAnimationEnd={handleInputAnimationComplete}
        />
        <div className={s.searchIcon}>
          <Image imageSet={[{ src: searchImage }]} />
        </div>
        {!searchValue && (
          <div className={cx(s.termsAndSuggestions, inputAnimationComplete ? s.skipDelay : '')}>
            <div className={s.termsAndSuggestionsInner}>
              <h3>TRENDING SEARCHES</h3>
              <ul>
                {trendingTerms.map((trend, index) => {
                  return (
                    <li key={`${trend}-${uuidv4()}`}>
                      <button
                        className={s.trendingButton}
                        type="button"
                        onClick={() => {
                          sessionStorage.setItem(TRENDING_INDEX_SESSION, index);
                          handleTermClick(trend);
                        }}
                        name={trend}
                      >
                        {trend}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        {searchValue && searchSuggestions.length > 0 && (
          <div className={cx(s.termsAndSuggestions, inputAnimationComplete ? s.skipDelay : '')}>
            <div className={s.termsAndSuggestionsInner}>
              <ul data-testid="suggestedTrending">
                {searchSuggestions.map((suggestion) => {
                  return (
                    <li key={`${suggestion}-${uuidv4()}`}>
                      <button
                        className={s.suggestedButton}
                        type="button"
                        onClick={() => handleTermClick(suggestion)}
                        name={suggestion}
                      >
                        {suggestion}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

SearchInput.propTypes = propTypes;
export default SearchInput;
