import React from 'react';
import s from './HourglassSpinner.module.scss';
import hourglass from './imgs/hourglass.svg';

const HourglassSpinner = () => (
  <div className={s.container}>
    <div className={s.hourglass} style={{ '--hourglass': `url(${hourglass})` }} />
  </div>
);

export default HourglassSpinner;
