import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import SharedLib from 'wizarding-world-web-shared';
import { useGlobalState as useSearchGlobalState } from '@/src/globalStates/SearchState';
import { REGISTER } from '@constants/routes';
import { trackClickEvent } from '@utils/analytics';
import { SIGNUP_NOTIFICATION_SEEN } from '@constants/index';
import Button from '../Button';
import SignUpPanel from '../SignUpPanel';
import s from './SignUpBtn.module.scss';

const SignUpBtn = () => {
  const router = useRouter();
  const { getCookie } = SharedLib.utils.cookie;
  const [isSearching] = useSearchGlobalState('isSearching');

  const [isButtonOutlineAnimationComplete, setButtonOutlineAnimationComplete] = useState(false);
  const [isButtonFillAnimationComplete, setButtonFillAnimationComplete] = useState(false);
  const [isPanelAnimationComplete, setPanelAnimationComplete] = useState(false);
  const [isSignUpHovered, setSignUpHovered] = useState(false);
  const [signupNotificationSeen, setSignupNotificationSeen] = useState(null);
  const [isDelayed, setDelayed] = useState(true);

  useEffect(() => {
    setSignupNotificationSeen(getCookie(SIGNUP_NOTIFICATION_SEEN) === 'true');
  }, [getCookie]);

  useEffect(() => {
    let startDelayTimer;
    if (signupNotificationSeen) {
      setDelayed(false);
    } else {
      startDelayTimer = setTimeout(
        /* istanbul ignore next */ () => {
          setDelayed(false);
        },
        1000,
      );
    }

    return () => clearTimeout(startDelayTimer);
  }, [signupNotificationSeen]);

  const onSignUpAnimationComplete = () => {
    if (!isButtonOutlineAnimationComplete) {
      setButtonOutlineAnimationComplete(true);
    } else {
      setButtonFillAnimationComplete(true);
    }
  };

  const onPanelCompleted = () => {
    setPanelAnimationComplete(true);
  };

  return (
    <div
      className={[
        s.root,
        isDelayed ? s.delayed : '',
        !(isPanelAnimationComplete || signupNotificationSeen || isButtonFillAnimationComplete)
          ? s.outlineAnimation
          : '',
        isPanelAnimationComplete ? s.fillInButton : '',
      ].join(' ')}
    >
      <Button
        onAnimationEnd={onSignUpAnimationComplete}
        label="Sign Up"
        aria-label="Sign Up"
        onClick={() => {
          trackClickEvent({
            destination_url: REGISTER,
            label: 'Sign Up',
            location: 'Nav Bar',
            vertical_index: 0,
            horizontal_index: 0,
          });
          router.push(REGISTER);
        }}
        data-testid="wizarding_world_signup"
        onMouseEnter={() => setSignUpHovered(true)}
        onMouseLeave={() => setSignUpHovered(false)}
        onFocus={() => setSignUpHovered(true)}
        onBlur={() => setSignUpHovered(false)}
      />
      {isButtonOutlineAnimationComplete && !isSearching && !isPanelAnimationComplete && (
        <SignUpPanel
          onComplete={onPanelCompleted}
          signupNotificationSeen={signupNotificationSeen}
          isSignupBtnHovered={isSignUpHovered}
        />
      )}
    </div>
  );
};

export default SignUpBtn;
