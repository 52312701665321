import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';
import s from './HamburgerMenu.module.scss';

const propTypes = {
  open: PropTypes.bool,
};

const hamburgerAnimation = new TimelineLite({ paused: true });

const HamburgerMenu = ({ open = false }) => {
  const topLightningRef = useRef();
  const middleLightningRef = useRef();
  const bottomLightningRef = useRef();

  useEffect(() => {
    hamburgerAnimation
      .to(topLightningRef.current, 0.1, {
        transformOrigin: '70% top',
        strokeDasharray: '0% 0% 100% 0%',
      })
      .to(
        bottomLightningRef.current,
        0.1,
        {
          transformOrigin: '20% bottom',
          strokeDasharray: '0% 0% 100% 0%',
        },
        0,
      )
      .addLabel('slideOutComplete')
      .to(
        topLightningRef.current,
        0.3,
        {
          y: 7,
          strokeDasharray: '0% 30% 70% 0%',
          rotation: 70,
          delay: 0.3,
        },
        'slideOutComplete',
      )
      .to(
        bottomLightningRef.current,
        0.3,
        {
          x: 3,
          y: -5,
          strokeDasharray: '0% 30% 70% 0%',
          rotation: 70,
          delay: 0.3,
        },
        'slideOutComplete',
      )
      .to(
        middleLightningRef.current,
        0.3,
        {
          transformOrigin: '25% 50%',
          strokeDasharray: '0% 20% 58% 22%',
          rotation: 10,
          delay: 0.3,
        },
        'slideOutComplete',
      )
      .addLabel('lightningComplete')
      .to(
        topLightningRef.current,
        0.2,
        {
          y: 5,
          x: -1,
          strokeDasharray: '0% 0% 100% 0%',
          rotation: -45,
          delay: 0.1,
        },
        'lightningComplete',
      )
      .to(
        bottomLightningRef.current,
        0.2,
        {
          y: -16,
          x: 5,
          strokeDasharray: '0% 0% 100% 0%',
          rotation: 405,
          delay: 0.1,
          transformOrigin: 'center bottom',
          force3D: true,
        },
        'lightningComplete',
      )
      .to(
        middleLightningRef.current,
        0.2,
        {
          opacity: 0,
        },
        'lightningComplete',
      );
  }, []);

  useEffect(() => {
    if (open) {
      hamburgerAnimation.play();
    } else {
      hamburgerAnimation.reverse(0);
    }
  }, [open]);

  return (
    <svg
      data-testid={`HamburgerMenuIcon${open ? 'Open' : 'Closed'}`}
      width="100%"
      height="100%"
      viewBox="0 0 23 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="#FFFFFF" strokeWidth="2">
        <path
          ref={topLightningRef}
          d="M0,0.5 L23,0.5"
          className={s.hamburgerTop}
          transform="rotate(0)"
        />
        <path ref={middleLightningRef} d="M0,9.5 L23,9.5" className={s.hamburgerMiddle} />
        <path ref={bottomLightningRef} d="M23,18.5 L0,18.5" className={s.hamburgerBottom} />
      </g>
    </svg>
  );
};

HamburgerMenu.propTypes = propTypes;
export default HamburgerMenu;
