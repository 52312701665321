export const SUGGEST_DEBOUNCE_TIMEOUT = 300;
export const NUMBER_OF_ITEMS_PER_PAGE = 20;
export const SEARCH_FILTERS = {
  everything: 'everything',
  articles: 'articles',
  news: 'news',
  quizzes: 'quizzes',
  writingByJKRowling: 'writing-by-jk-rowling',
  curatedPages: 'curated-pages',
  factFiles: 'fact-files',
};

export const SEARCH_SORTS = {
  relevance: 'relevance',
  oldToNew: 'old-to-new',
  newToOld: 'new-to-old',
};

export const SEARCH_TRENDING_TERMS = [
  'House Sorting',
  'Harry Potter TV Series',
  'Quizzes',
  'Hogwarts Legacy',
  'Harry Potter',
  'Fantastic Beasts',
  'Hermione Granger',
  'Back to Hogwarts',
];

export const TRUNCATED_LENGTH = 250;
export const SEARCH_CHARACTER_LIMIT = 300;
export const TRENDING_INDEX_SESSION = 'trendingSearchIndex';
export const TRENDING_TERMS_EXTERNAL_ID = 'trending-search-terms-config';

// swiftype
const SWIFTYPE_BASE_API_URL = 'https://api.swiftype.com/api/v1/public/engines';
export const SWIFTYPE_SEARCH_API_URL = `${SWIFTYPE_BASE_API_URL}/search.json`;
export const SWIFTYPE_SUGGEST_API_URL = `${SWIFTYPE_BASE_API_URL}/suggest.json`;
export const SWIFTYPE_SEARCH_DOCUMENT_TYPES = {
  article: 'article',
  collection: 'collection',
  page: 'page',
  searchResult: 'searchresult',
  writingByJKRowling: 'writingbyjkrowling',
  factFilePage: 'factfilepage',
};

// motif
const MOTIF_BASE_API_URL = process.env.REACT_APP_SEARCH_API;
export const MOTIF_SEARCH_API_URL = `${MOTIF_BASE_API_URL}`;
export const MOTIF_SUGGEST_API_URL = `${MOTIF_BASE_API_URL}/suggested`;
export const MOTIF_SEARCH_DOCUMENT_TYPES = {
  article: 'article',
  collection: 'collection',
  page: 'page',
  searchResult: 'searchResult',
  writingByJKRowling: 'writingByJKRowling',
  factFilePage: 'factFilePage',
};
export const MOTIF_FILTER_TO_FACET_MAP = {
  [SEARCH_FILTERS.articles]: 'articles',
  [SEARCH_FILTERS.news]: 'news',
  [SEARCH_FILTERS.quizzes]: 'quizzes',
  [SEARCH_FILTERS.writingByJKRowling]: 'jkRowlingArchive',
  [SEARCH_FILTERS.curatedPages]: 'curatedPages',
  [SEARCH_FILTERS.factFiles]: 'factFiles',
};
