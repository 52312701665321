import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import { trackEvent } from '../../utils/analytics';
import s from './Bumper.module.scss';
import Button from '../Button';

const InfoModal = dynamic(() => import('../InfoModal'), {
  ssr: false,
});

const siteURL = process.env.REACT_APP_SSR_URI?.includes('harrypotter')
  ? 'HarryPotter.com'
  : 'WizardingWorld.com';
const defaultTitle = `Thank you for visiting ${siteURL}`;
const defaultDescription =
  'You are now leaving our website and heading to the Harry Potter shop, which has a different privacy policy and terms of service that may not apply to children.';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.string,
  cancel: PropTypes.string,
  btnLabel: PropTypes.string,
  isOpen: PropTypes.bool,
  link: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  eventName: PropTypes.string,
};

const Bumper = ({
  title = defaultTitle,
  description = defaultDescription,
  btnLabel = 'CONTINUE',
  cancel = 'CANCEL',
  link,
  isOpen = false,
  onClose,
  eventName = '',
  location = 'Leaving Wizarding World Bumper',
}) => (
  <InfoModal isOpen={isOpen} eventName={eventName} location={location} bumper toggleModal={onClose}>
    <div className={s.inner} data-testid="bumper">
      <h1 className={s.title}>{title}</h1>
      <div className={s.description}>{description}</div>
      <a
        href={link}
        className={s.link}
        onClick={() => {
          trackEvent(eventName, { location, cta_text: btnLabel });
          onClose();
        }}
      >
        <Button label={btnLabel} tabIndex="-1" />
      </a>
      <Button
        type="text"
        label={cancel}
        tabIndex="-1"
        className={s.cancel}
        onClick={() => {
          trackEvent(eventName, { location, cta_text: cancel });
          onClose();
        }}
      />
    </div>
  </InfoModal>
);

Bumper.propTypes = propTypes;
export default Bumper;
