export const AVATAR_LOCAL_IMAGE = 'avatarLocalImage';
export const AVATAR_FULL_IMAGE_SIZE = 1080;
export const AVATAR_TEMP_IMAGE_SIZE = 550;
export const AVATAR_NEW_ITEM_DAY_COUNT = 90;

export const AVATAR_REQUIRED_SLOTS = [
  'body',
  'background',
  'clothing',
  'eyes',
  'facialShape',
  'hair',
  'mouth',
  'nose',
  'frame',
];

export const AVATAR_LINKED_SKIN_SLOTS = ['body', 'nose', 'mouth', 'hand'];

export const AVATAR_LAYER_CATEGORIES = [
  'background',
  'pet',
  'clothing',
  'frontAccessory',
  'backAccessory',
  'facialShape',
  'body',
  'eyes',
  'nose',
  'mouth',
  'eyeDecorator',
  'facialDecorator',
  'facialHair',
  'hair',
  'hand',
  'frame',
];

// The order of this array defines the z-index of layers
// in the avatar canvas.
export const AVATAR_LAYER_INDEX = [
  'background',
  'backAccessory',
  'pet',
  'hair-secondary',
  'body',
  'clothing',
  'frontAccessory',
  'facialShape',
  'eyes',
  'nose',
  'mouth',
  'facialDecorator',
  'facialHair',
  'eyeDecorator',
  'hair',
  'hand',
  'frame',
];

export const SHOW_SHARE_YOUR_PORTRAIT = 'showSharePortraitModal';
export const HASHTAG_FALLBACK = '#myWizardingPortrait';
