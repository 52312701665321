import React, { useState, useEffect, useRef, useCallback } from 'react';
import cx from 'classnames';
import SearchClient from '@utils/search/searchClient';
import { useGlobalState as useNavigationGlobalState } from '@/src/globalStates/NavigationState';
import { setIsSearching } from '@/src/globalStates/SearchState';
import { useWindowWidth } from '@hooks/windowHooks';
import { breakpoints } from '@hooks/useBreakpoints';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';
import { useRouter } from 'next/router';
import { SUGGEST_DEBOUNCE_TIMEOUT, TRENDING_INDEX_SESSION } from '@constants/search';
import { SEARCH } from '@constants/routes';
import PropTypes from 'prop-types';
import Image from '../Image';
import searchImage from '../../images/icons/search.svg';
import s from './SearchInputMobile.module.scss';

const propTypes = {
  onCancel: PropTypes.func.isRequired,
};

const SearchInputMobile = ({ onCancel }) => {
  const [trendingTerms, setTrendingTerms] = useState([]);
  const [visible, setVisible] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [navigationHiddenByScroll] = useNavigationGlobalState('navigationHiddenByScroll');
  const windowWidth = useWindowWidth();
  const searchClient = new SearchClient();
  const searchRef = useRef(null);
  const router = useRouter();

  const fetchSuggestedTerms = useCallback(
    debounce(async (searchTerm) => {
      const suggestedTermsResponse = await searchClient.suggestTerms(searchTerm);
      setSearchSuggestions(suggestedTermsResponse);
    }, SUGGEST_DEBOUNCE_TIMEOUT),
    [],
  );

  const onSearchInputChange = (event) => {
    setSearchValue(event.target.value);
    fetchSuggestedTerms(event.target.value);
  };

  const goToSearch = (searchTerm) => {
    if (router.pathname === SEARCH && searchTerm === '') return;
    document.activeElement.blur();
    router.push(searchTerm ? `/search?term=${encodeURIComponent(searchTerm)}` : '/search');
    searchRef.current.value = null;
    setSearchValue(null);
  };

  const handleSearchKeyDown = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      goToSearch(event.target.value);
    }
  };

  const handleTermClick = (trend) => {
    goToSearch(trend);
  };

  const onCancelClick = () => {
    setVisible(false);
  };

  const onSearchBlur = () => {
    setTimeout(() => {
      setIsSearching(false);
      setVisible(false);
    }, 200);
  };

  /* istanbul ignore next */
  const onAnimationEnd = (e) => {
    if (e.animationName === s.fadeOut) {
      onCancel();
    }
  };

  useEffect(() => {
    /* istanbul ignore next */
    if (navigationHiddenByScroll) {
      searchRef.current.blur();
    }
  }, [navigationHiddenByScroll]);

  useEffect(() => {
    if (visible && windowWidth < breakpoints.md) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
  }, [visible, windowWidth]);

  useEffect(() => {
    const fetchTrendingTerms = async () => {
      setTrendingTerms(await searchClient.getTrendingTerms());
    };
    setIsSearching(true);
    searchRef.current.focus();
    fetchTrendingTerms();
  }, []);

  return (
    <div
      data-testid="searchInputMobile"
      className={cx(s.searchInputMobile, visible ? s.fadeIn : s.fadeOut)}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={s.searchInputWrapper}>
        <div className={s.inputAndDropdown}>
          <ul className={s.searchTermsList} data-testid="suggestedTrending">
            {!searchValue && (
              <>
                <li className={cx(s.trendingLabel)}>
                  <h3>TRENDING SEARCHES</h3>
                </li>
                {trendingTerms.map((trend, index) => {
                  return (
                    <li className={cx(s.termLi)} key={`${trend}-${uuidv4()}`}>
                      <button
                        type="button"
                        onClick={() => {
                          sessionStorage.setItem(TRENDING_INDEX_SESSION, index);
                          handleTermClick(trend);
                        }}
                        name={trend}
                      >
                        {trend}
                      </button>
                    </li>
                  );
                })}
              </>
            )}

            {searchValue && searchSuggestions.length > 0 && (
              <>
                {searchSuggestions.map((suggestion) => {
                  return (
                    <li key={`${suggestion}-${uuidv4()}`} className={cx(s.termLi)}>
                      <button
                        type="button"
                        onClick={() => handleTermClick(suggestion)}
                        name={suggestion}
                      >
                        {suggestion}
                      </button>
                    </li>
                  );
                })}
              </>
            )}
          </ul>
          <div className={s.searchIconWrapper}>
            <Image imageSet={[{ src: searchImage }]} />
          </div>
          <input
            type="text"
            name="Search"
            className={s.searchInput}
            onChange={onSearchInputChange}
            onKeyDown={handleSearchKeyDown}
            onBlur={onSearchBlur}
            data-testid="searchInputHeader"
            placeholder="Search"
            ref={searchRef}
          />
        </div>

        <button className={s.cancelButton} type="button" onClick={onCancelClick}>
          Cancel
        </button>
      </div>
    </div>
  );
};

SearchInputMobile.propTypes = propTypes;
export default SearchInputMobile;
