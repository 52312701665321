import React from 'react';
import PropTypes from 'prop-types';
import { trackClickEvent } from '@utils/analytics';
import { useGlobalState as useNavigationGlobalState } from '@/src/globalStates/NavigationState';
import AnchorLink from '../AnchorLink';
import s from './NavRibbon.module.scss';

const propTypes = {
  navData: PropTypes.shape({
    ribbonMessageText: PropTypes.string,
    ribbonBackgroundHexCode: PropTypes.string,
    ribbonLinkURL: PropTypes.string,
    ribbonCTAText: PropTypes.string,
  }).isRequired,
};

const NavRibbon = ({ navData }) => {
  const [navigationHiddenByScroll] = useNavigationGlobalState('navigationHiddenByScroll');
  const [blur] = useNavigationGlobalState('blur');
  const { ribbonMessageText, ribbonBackgroundHexCode, ribbonLinkURL, ribbonCTAText } = navData;
  const textContent = (
    <div className={s.textContainer}>
      {ribbonMessageText}
      {ribbonCTAText && <div className={s.ctaText}>{ribbonCTAText}</div>}
    </div>
  );
  return (
    <div
      data-testid="navRibbonRoot"
      className={[
        s.navRibbon,
        blur ? s.blur : '',
        navigationHiddenByScroll ? s.hideRibbon : '',
      ].join(' ')}
      style={{ backgroundColor: ribbonBackgroundHexCode }}
    >
      {ribbonLinkURL ? (
        <AnchorLink
          href={ribbonLinkURL}
          onClick={() => {
            trackClickEvent({
              destination_url: ribbonLinkURL,
              label: ribbonCTAText,
              location: 'Ribbon',
              vertical_index: 0,
              horizontal_index: -1,
              content_name: ribbonMessageText,
            });
          }}
        >
          {textContent}
        </AnchorLink>
      ) : (
        textContent
      )}
    </div>
  );
};

NavRibbon.propTypes = propTypes;
export default NavRibbon;
