import { SEARCH_TRENDING_TERMS, TRENDING_TERMS_EXTERNAL_ID } from '@/src/constants/search';
import { fetchContent } from '@utils/contentfulHelpers';
import { swiftypeSearch, swiftypeSuggest, swiftypeSuggestTerms } from '@utils/search/swiftype';
import { motifSearch, motifSuggest, motifSuggestTerms } from '@utils/search/motif';

const getTrendingTerms = async () => {
  const [content, errors] = await fetchContent({
    contentTypes: ['commonTextSection'],
    externalId: TRENDING_TERMS_EXTERNAL_ID,
  });

  if (!content || errors) {
    return SEARCH_TRENDING_TERMS;
  }

  return content?.[0]?.body?.text?.split('\n') || SEARCH_TRENDING_TERMS;
};

class SearchClient {
  constructor() {
    const clientType = process.env.REACT_APP_SEARCH_SERVICE === 'MOTIF' ? 'motif' : 'swiftype';

    // istanbul ignore else
    if (clientType === 'swiftype') {
      this.search = swiftypeSearch;
      this.suggest = swiftypeSuggest;
      this.suggestTerms = swiftypeSuggestTerms;
    } else if (clientType === 'motif') {
      this.search = motifSearch;
      this.suggest = motifSuggest;
      this.suggestTerms = motifSuggestTerms;
    } else {
      throw new Error(`Invalid search client: ${clientType}`);
    }

    this.getTrendingTerms = getTrendingTerms;
  }
}

export default SearchClient;
