import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  isSearching: false,
};

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState(initialState);

export const setIsSearching = (isSearching) => {
  setGlobalState('isSearching', isSearching);
};

export { useGlobalState, getGlobalState };
