import { useWindowWidth } from './windowHooks';

// if these ever change they also need to be updated in variables.scss
export const breakpoints = {
  xs: 0,
  sm: 576,
  ss: 700,
  md: 768,
  xm: 900,
  lg: 1042,
  lgg: 1150, // special case for navbar only
  xl: 1320,
  xxl: 2200,
};

export default function useBreakpoints() {
  const width = useWindowWidth();

  return {
    xlUp: width >= breakpoints.xl,
    lgUp: width >= breakpoints.lg,
    mdUp: width >= breakpoints.md,
    smUp: width >= breakpoints.sm,
    lgDown: width < breakpoints.xl,
    mdDown: width < breakpoints.lg,
    smDown: width < breakpoints.md,
    xsDown: width < breakpoints.sm,
  };
}
